require('./bootstrap');

// QuickAdmin Setup
require('../assets/js/bootstrap-datetimepicker.min');
require('../assets/js/pdfmake.min');
require('../assets/js/perfect-scrollbar.min');
require('../assets/js/select2.full.min');
require('../assets/js/vfs_fonts');
require('jquery/dist/jquery.slim');

// jQuery DataTable

require('datatables.net/js/jquery.dataTables');
require('datatables.net-bs5/js/dataTables.bootstrap5');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons-bs5/js/buttons.bootstrap5');
require('datatables.net-select-bs5/js/select.bootstrap5');

require('firebase/firebase-messaging')

// Core Ui & Bootstrap
import * as coreui from '@coreui/coreui';
import * as bootstrap from 'bootstrap';
