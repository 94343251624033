window._ = require('lodash');

window.$ = window.jQuery = require('jquery');

window.Popper = require('@popperjs/core').default;

window.axios = require('axios');

window.Chart = require('chart.js/auto');

window.Dropzone = require('../assets/js/dropzone.min');

window.JSZip = require('jszip');

window.ClassicEditor = require('../assets/js/ckeditor');

window.moment = require('moment/moment');

window.Swal = require('sweetalert2')

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import firebase from "firebase/app";
window.firebase = firebase;

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
